import React, { useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const GalleryWithLightbox = ({ imagenes }) => {
  const [index, setIndex] = useState(-1);

  const handleClick = (selectedIndex) => setIndex(selectedIndex);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () =>
    setIndex((index + imagenes.length - 1) % imagenes.length);
  const handleMoveNext = () => setIndex((index + 1) % imagenes.length);

  const currentImage = imagenes[index];
  const nextIndex = (index + 1) % imagenes.length;
  const nextImage = imagenes[nextIndex] || currentImage;
  const prevIndex = (index + imagenes.length - 1) % imagenes.length;
  const prevImage = imagenes[prevIndex] || currentImage;

  const processedImages = imagenes.map((image, i) => ({
    ...image,
    alt: image.alt || `Imagen clickeable ${i + 1} de ${imagenes.length}`,
  }));

  return (
    <>
      <Gallery
        images={processedImages}
        enableLightbox={true}
        onClick={handleClick}
        enableImageSelection={true}
        aria-label="Galería de imágenes"
      />
      {!!currentImage && (
        <Lightbox
          mainSrc={currentImage.src}
          imageTitle={`Imagen ${index + 1} de ${imagenes.length}`}
          mainSrcThumbnail={currentImage.thumbnail}
          nextSrc={nextImage.src}
          nextSrcThumbnail={nextImage.thumbnail}
          prevSrc={prevImage.src}
          prevSrcThumbnail={prevImage.thumbnail}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
          toolbarButtons={[
            <span
              key="contador"
              aria-live="polite"
              aria-label={`Imagen ${index + 1} de ${imagenes.length}`}
            >
              {index + 1} / {imagenes.length}
            </span>,
          ]}
        />
      )}
    </>
  );
};

export default GalleryWithLightbox;
