import { queueMessage } from "~shared/snackbarsProvider/actions";
import { createAction, handleActions } from "redux-actions";

const getAnulacionesInited = createAction(
  "GET_REPORTE_ANULACIONES_INITED",
  (data) => ({ data })
);
const getAnulacionesCompleted = createAction(
  "GET_REPORTE_ANULACIONES_COMPLETED"
);
const getAnulacionesFailed = createAction(
  "GET_REPORTE_ANULACIONES_FAILED",
  (error) => ({ error })
);

const setFechaDesdeAnulaciones = createAction(
  "SET_FECHA_DESDE_ANULACION",
  (fechaDesdeAnulacionesSelected) => ({
    fechaDesdeAnulacionesSelected,
  })
);
const setFechaHastaAnulaciones = createAction(
  "SET_FECHA_HASTA_ANULACION",
  (fechaHastaAnulacionesSelected) => ({
    fechaHastaAnulacionesSelected,
  })
);

let date = new Date();
let defaultDesde = new Date(date.getFullYear(), date.getMonth(), 1);
let defaultHasta = new Date(date.getFullYear(), date.getMonth() + 1, 0);
const getAnulacionesInitialState = {
  fechaDesdeAnulacionesSelected: {
    value: defaultDesde,
    valid: true,
    focus: false,
  },
  fechaHastaAnulacionesSelected: {
    value: defaultHasta,
    valid: false,
    focus: false,
  },
  buttonEnabled: true,
  buttonText: "Descargar",
};

const getAnulacionesReducer = handleActions(
  {
    [getAnulacionesInited]: (state, action) => {
      return {
        ...state,
        buttonEnabled: false,
        buttonText: "Descargando...",
      };
    },
    [getAnulacionesCompleted]: (state, action) => {
      return {
        ...state,
        buttonEnabled: true,
        buttonText: "Descargar",
      };
    },
    [getAnulacionesFailed]: (state, action) => {
      return {
        ...state,
        errorMessage: action.payload.error.responseStatus.message,
        buttonEnabled: true,
        buttonText: "Descargar",
      };
    },
    [setFechaDesdeAnulaciones]: (state, action) => {
      return {
        ...state,
        fechaDesdeAnulacionesSelected:
          action.payload.fechaDesdeAnulacionesSelected,
      };
    },
    [setFechaHastaAnulaciones]: (state, action) => {
      return {
        ...state,
        fechaHastaAnulacionesSelected:
          action.payload.fechaHastaAnulacionesSelected,
      };
    },
  },
  getAnulacionesInitialState
);

const getAnulaciones = (data) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getAnulacionesInited(data));
      let response = await services.api.reportes().getAnulaciones(data);

      // create a fake anchor and click it programatically to
      // force the file to download
      var xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
          var downloadUrl = URL.createObjectURL(xhttp.response);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = downloadUrl;
          a.download = "";
          a.click();
        }
      };

      xhttp.open("GET", response.url, true);
      xhttp.responseType = "blob";
      xhttp.send();

      dispatch(getAnulacionesCompleted());
    } catch (error) {
      console.error(error);
      dispatch(getAnulacionesFailed(error));
      dispatch(
        queueMessage(
          "Ocurrió un error al descargar el reporte. Intente nuevamente."
        )
      );
    }
  };
};

export {
  getAnulaciones,
  getAnulacionesReducer,
  setFechaDesdeAnulaciones,
  setFechaHastaAnulaciones,
};
