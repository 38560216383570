import { createAction, handleActions } from "redux-actions";
import { queueMessage } from "~shared/snackbarsProvider/actions";

const moment = require("moment");

const setSubmitting = createAction(
  "SET_SUBMITTING_ALTA_MULTI",
  (submitting) => ({
    submitting,
  })
);

const setNumeroPoliza = createAction(
  "SET_NUMERO_POLIZA_MULTI",
  (numeroPolizaSelected) => ({
    numeroPolizaSelected,
  })
);

const setAseguradoNombre = createAction(
  "SET_NOMBRE_MULTI",
  (aseguradoNombreSelected) => ({
    aseguradoNombreSelected,
  })
);

const setAseguradoApellido = createAction(
  "SET_APELLIDO_MULTI",
  (aseguradoApellidoSelected) => ({
    aseguradoApellidoSelected,
  })
);

const setAseguradoNumeroDocumento = createAction(
  "SET_DOCUMENTO_MULTI",
  (aseguradoNumeroDocumentoSelected) => ({
    aseguradoNumeroDocumentoSelected,
  })
);

const setDominio = createAction("SET_DOMINIO_MULTI", (dominioSelected) => ({
  dominioSelected,
}));

const setRama = createAction("SET_RAMA_MULTI", (ramaSelected) => ({
  ramaSelected,
}));

const setCompania = createAction("SET_COMPANIA_MULTI", (companiaSelected) => ({
  companiaSelected,
}));

const setUsuario = createAction("SET_USUARIO_MULTI", (usuarioSelected) => ({
  usuarioSelected,
}));

const setCobertura = createAction(
  "SET_COBERTURA_MULTI",
  (coberturaSelected) => ({
    coberturaSelected,
  })
);

const clearUsuario = createAction("CLEAR_USUARIO_MULTI", () => ({}));

const setVigencia = createAction("SET_VIGENCIA_MULTI", (vigenciaSelected) => ({
  vigenciaSelected,
}));

const setCantidadCuotas = createAction(
  "SET_CANTIDAD_CUOTAS_MULTI",
  (cuotasSelected) => ({
    cuotasSelected,
  })
);

const setFormaPago = createAction(
  "SET_MEDIO_PAGO_MULTI",
  (formaPagoSelected) => ({
    formaPagoSelected,
  })
);

const setVigenciaDesde = createAction(
  "SET_VIGENCIA_DESDE_MULTI",
  (vigenciaDesdeSelected) => ({
    vigenciaDesdeSelected,
  })
);
const setVigenciaHasta = createAction(
  "SET_VIGENCIA_HASTA_MULTI",
  (vigenciaHastaSelected) => ({
    vigenciaHastaSelected,
  })
);

const setPremio = createAction("SET_PREMIO_MULTI", (premioSelected) => ({
  premioSelected,
}));

const setPolizaFileName = createAction(
  "SET_POLIZA_FILENAME_MULTI",
  (polizaFileNameSelected) => ({
    polizaFileNameSelected,
  })
);

const setCuponeraFileName = createAction(
  "SET_CUPONERA_FILENAME_MULTI",
  (cuponeraFileNameSelected) => ({
    cuponeraFileNameSelected,
  })
);

const setCedulaMercosurFileName = createAction(
  "SET_CEDULAMERCOSUR_FILENAME_MULTI",
  (cedulaMercosurFileNameSelected) => ({
    cedulaMercosurFileNameSelected,
  })
);

const setTarjetaFileName = createAction(
  "SET_TARJETA_FILENAME_MULTI",
  (tarjetaFileNameSelected) => ({
    tarjetaFileNameSelected,
  })
);

const setIsProcessingPoliza = createAction(
  "SET_PROCESSING_POLIZA_MULTI",
  (isProcessingPoliza) => ({
    isProcessingPoliza,
  })
);

const setIsProcessingCuponera = createAction(
  "SET_PROCESSING_CUPONERA_MULTI",
  (isProcessingCuponera) => ({
    isProcessingCuponera,
  })
);

const setIsProcessingCedulaMercosur = createAction(
  "SET_PROCESSING_CEDULAMERCOSUR_MULTI",
  (isProcessingCedulaMercosur) => ({
    isProcessingCedulaMercosur,
  })
);

const setIsProcessingTarjeta = createAction(
  "SET_PROCESSING_TARJETA_MULTI",
  (isProcessingTarjeta) => ({
    isProcessingTarjeta,
  })
);

const getAseguradoInited = createAction("OBTENER_ASEGURADO_INITED");

const getAsegurado = (documento) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getAseguradoInited());

      const response = await services.api
        .polizasMultirama()
        .getAsegurado(documento);

      dispatch(getAseguradoCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getAseguradoFailed(error));
    }
  };
};

const getAseguradoCompleted = createAction(
  "OBTENER_ASEGURADO_COMPLETED",
  (asegurado) => ({
    asegurado,
  })
);

const getAseguradoFailed = createAction(
  "OBTENER_ASEGURADO_FAILED",
  (error) => ({
    error,
  })
);

const getRamasInited = createAction("OBTENER_RAMAS_INITED");

const getRamas = () => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getRamasInited());

      const response = await services.api.polizasMultirama().getRamas();

      dispatch(getRamasCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getRamasFailed(error));
    }
  };
};

const getRamasCompleted = createAction(
  "OBTENER_RAMAS_COMPLETED",
  (response) => {
    let ramas = [];

    if (
      typeof response !== "undefined" &&
      response !== null &&
      response.length !== 0
    ) {
      ramas = response;
    }

    return { ramas };
  }
);

const getRamasFailed = createAction("OBTENER_RAMAS_FAILED", (error) => ({
  error,
}));

const getUsuariosInited = createAction("OBTENER_USUARIOS_MULTI_INITED");

const getUsuarios = (skip, take, searchCriteria) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getUsuariosInited());

      const data = {
        skip: skip,
        take: take,
        searchCriteria: searchCriteria,
      };

      const response = await services.api.usuarios().getUsuarios(data);

      dispatch(getUsuariosCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(getUsuariosFailed(error));
    }
  };
};

const getUsuariosCompleted = createAction(
  "OBTENER_USUARIOS_MULTI_COMPLETED",
  (response) => {
    let usuarios = [];

    if (
      typeof response !== "undefined" &&
      response !== null &&
      response.length !== 0
    ) {
      usuarios = response.usuarios.filter((usuario) => usuario !== "");
    }

    return { usuarios };
  }
);

const getUsuariosFailed = createAction(
  "OBTENER_USUARIOS_MULTI_FAILED",
  (error) => ({
    error,
  })
);

const postPolizaMultiramaInited = createAction(
  "PROCESAR_SOLICITUD_MULTI_INITED"
);

const postPolizaMultiramaCompleted = createAction(
  "PROCESAR_SOLICITUD_MULTI_COMPLETED",
  (response) => { }
);

const postPolizaMultiramaFailed = createAction(
  "PROCESAR_SOLICITUD_MULTI_FAILED",
  (error) => ({
    error,
  })
);

const postPolizaMultirama = (data) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(postPolizaMultiramaInited());

      const response = await services.api
        .polizasMultirama()
        .postPolizaMultirama(data);

      dispatch(postPolizaMultiramaCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(postPolizaMultiramaFailed(error));

      dispatch(queueMessage(error.responseStatus.errors[0].message));
    }
  };
};

const clearData = createAction("CLEAR_DATA_MULTI", () => ({}));

const altaPolizaMultiramaReducerInitialState = {
  submitting: false,
  numeroPolizaSelected: { value: "", valid: false, focus: false },
  aseguradoNombreSelected: { value: "", valid: false, focus: false },
  aseguradoApellidoSelected: { value: "", valid: false, focus: false },
  aseguradoIdSelected: null,
  aseguradoNumeroDocumentoSelected: { value: "", valid: false, focus: false },
  dominioSelected: { value: "", valid: false, focus: false },
  ramaSelected: {
    value: "motovehiculo",
    valid: false,
    focus: false,
    tieneDominio: true,
  },
  asegurado: null,
  ramasFilter: null,
  ramasOptions: [],
  companiaSelected: { value: "Sancor", valid: false, focus: false },
  coberturaSelected: { value: "", valid: false, focus: false },
  usuarioSelected: {
    value: "0a10c226-3c6a-4c18-99b5-271ff17c4a40",
    valid: false,
    focus: false,
  },
  usuariosFilter: [],
  usuariosOptions: [],
  vigenciaSelected: { value: 6, valid: true, focus: false },
  cuotasSelected: { value: 6, valid: false, focus: false },
  formaPagoSelected: {
    value: "DebitoConTarjetaCredito",
    valid: true,
    focus: false,
  },
  vigenciaDesdeSelected: { value: new Date(), valid: true, focus: false },
  vigenciaHastaSelected: { value: new Date(), valid: false, focus: false },
  premioSelected: { value: 0, valid: false, focus: false },
  polizaFileNameSelected: { value: null, valid: false, focus: false },
  cuponeraFileNameSelected: { value: null, valid: false, focus: false },
  cedulaMercosurFileNameSelected: { value: null, valid: false, focus: false },
  tarjetaFileNameSelected: { value: null, valid: false, focus: false },
  isProcessingPoliza: false,
  isProcessingCuponera: false,
  isProcessingCedulaMercosur: false,
  isProcessingTarjeta: false,
  creatingPoliza: false,
  polizaCreada: false,
  loadingError: false,
  isLoading: false,
  isLoadingAsegurado: false,
  isLoadingUsuarios: false,
  failed: false,
  currentPoliza: null,
  page: 0,
  take: 100,
  searchCriteria: "",
};

const altaPolizaMultiramaReducer = handleActions(
  {
    [setSubmitting]: (state, action) => {
      return {
        ...state,
        submitting: action.payload.submitting,
      };
    },
    [setNumeroPoliza]: (state, action) => {
      return {
        ...state,
        numeroPolizaSelected: action.payload.numeroPolizaSelected,
      };
    },
    [setAseguradoNombre]: (state, action) => {
      return {
        ...state,
        aseguradoNombreSelected: action.payload.aseguradoNombreSelected,
      };
    },
    [setAseguradoApellido]: (state, action) => {
      return {
        ...state,
        aseguradoApellidoSelected: action.payload.aseguradoApellidoSelected,
      };
    },
    [setAseguradoNumeroDocumento]: (state, action) => {
      return {
        ...state,
        aseguradoNumeroDocumentoSelected:
          action.payload.aseguradoNumeroDocumentoSelected,
      };
    },
    [setDominio]: (state, action) => {
      return {
        ...state,
        dominioSelected: action.payload.dominioSelected,
      };
    },
    [setRama]: (state, action) => {
      let ramaSelected = state.ramasFilter.find(
        (rama) => rama.value === action.payload.ramaSelected.value
      ); /* aca recibimos las opciones de ramaFilter y fusionamos su valor tieneDominio con la ramaSelected que viene del input, nos sirve para armar el condicional que muestra o no el input Dominio */

      ramaSelected = {
        ...action.payload.ramaSelected,
        tieneDominio: ramaSelected.tieneDominio,
      };

      return {
        ...state,
        ramaSelected: ramaSelected,
      };
    },
    [setCompania]: (state, action) => {
      return {
        ...state,
        companiaSelected: action.payload.companiaSelected,
      };
    },
    [setUsuario]: (state, action) => {
      return {
        ...state,
        usuarioSelected: action.payload.usuarioSelected,
      };
    },
    [setCobertura]: (state, action) => {
      return {
        ...state,
        coberturaSelected: action.payload.coberturaSelected,
      };
    },
    [clearUsuario]: (state, action) => {
      return {
        ...state,
        usuarioSelected: {
          value: "",
          valid: false,
          focus: false,
        },
      };
    },
    [setCantidadCuotas]: (state, action) => {
      return {
        ...state,
        cuotasSelected: action.payload.cuotasSelected,
      };
    },
    [setFormaPago]: (state, action) => {
      return {
        ...state,
        formaPagoSelected: action.payload.formaPagoSelected,
      };
    },
    [setVigencia]: (state, action) => {
      const vigencia = action.payload.vigenciaSelected;
      const vigenciaDesde = state.vigenciaDesdeSelected;
      return {
        ...state,
        vigenciaSelected: vigencia,
        vigenciaHastaSelected: {
          value: moment(vigenciaDesde.value).add(vigencia.value, "months"),
          valid: true,
          focus: false,
        },
      };
    },
    [setVigenciaDesde]: (state, action) => {
      const vigenciaDesde = action.payload.vigenciaDesdeSelected;
      const vigenciaHasta = new Date(vigenciaDesde.value);
      const vigencia = state.vigenciaSelected;

      return {
        ...state,
        vigenciaDesdeSelected: vigenciaDesde,
        vigenciaHastaSelected: {
          value: new Date(
            vigenciaHasta.setMonth(vigenciaHasta.getMonth() + vigencia.value)
          ),
          valid: true,
          focus: false,
        },
      };
    },
    [setVigenciaHasta]: (state, action) => {
      return {
        ...state,
        vigenciaHastaSelected: action.payload.vigenciaHastaSelected,
      };
    },
    [setPremio]: (state, action) => {
      return {
        ...state,
        premioSelected: action.payload.premioSelected,
      };
    },
    [setIsProcessingPoliza]: (state, action) => {
      return {
        ...state,
        isProcessingPoliza: action.payload.isProcessingPoliza,
      };
    },
    [setIsProcessingCuponera]: (state, action) => {
      return {
        ...state,
        isProcessingCuponera: action.payload.isProcessingCuponera,
      };
    },
    [setIsProcessingCedulaMercosur]: (state, action) => {
      return {
        ...state,
        isProcessingCedulaMercosur: action.payload.isProcessingCedulaMercosur,
      };
    },
    [setIsProcessingTarjeta]: (state, action) => {
      return {
        ...state,
        isProcessingTarjeta: action.payload.isProcessingTarjeta,
      };
    },
    [setPolizaFileName]: (state, action) => {
      return {
        ...state,
        polizaFileNameSelected: action.payload.polizaFileNameSelected,
      };
    },
    [setCuponeraFileName]: (state, action) => {
      return {
        ...state,
        cuponeraFileNameSelected: action.payload.cuponeraFileNameSelected,
      };
    },
    [setCedulaMercosurFileName]: (state, action) => {
      return {
        ...state,
        cedulaMercosurFileNameSelected:
          action.payload.cedulaMercosurFileNameSelected,
      };
    },
    [setTarjetaFileName]: (state, action) => {
      return {
        ...state,
        tarjetaFileNameSelected: action.payload.tarjetaFileNameSelected,
      };
    },
    [getRamasInited]: (state, action) => {
      return {
        ...state,
        isLoading: true,
        isLoadingAsegurado: true,
      };
    },
    [getRamasCompleted]: (state, action) => {
      let ramasFilter = action.payload.ramas.ramas.map((rama) => ({
        label: rama.nombre,
        value: rama.id,
        tieneDominio: rama.tieneDominio,
      }));

      let ramasOptions = {};

      action.payload.ramas.ramas.forEach((rama) => {
        ramasOptions[rama.id] = rama.nombre;
      });

      return {
        ...state,
        ramasFilter: ramasFilter,
        ramasOptions: ramasOptions,
        isLoading: false,
        isLoadingAsegurado: false,
      };
    },
    [getRamasFailed]: (state, action) => {
      return {
        ...state,
        loadingError: true,
        isLoading: false,
        isLoadingAsegurado: false,
      };
    },
    [getUsuariosInited]: (state, action) => {
      return {
        ...state,
        isLoadingUsuarios: true,
      };
    },
    [getUsuariosCompleted]: (state, action) => {
      let usuariosFilter = action.payload.usuarios.map((usuario) => ({
        label: usuario.nombre,
        value: usuario.id,
      }));

      let usuariosOptions = {};

      action.payload.usuarios.forEach((usuario) => {
        usuariosOptions[usuario.id] = usuario.nombre;
      });

      return {
        ...state,
        usuariosFilter: usuariosFilter,
        usuariosOptions: usuariosOptions,
        usuarios: action.payload.usuarios,
        isLoadingUsuarios: false,
      };
    },
    [getUsuariosFailed]: (state, action) => {
      return {
        ...state,
        isLoadingUsuarios: false,
      };
    },
    [getAseguradoInited]: (state, action) => {
      return {
        ...state,
        isLoadingAsegurado: true,
      };
    },
    [getAseguradoCompleted]: (state, action) => {
      return {
        ...state,
        asegurado: action.payload.asegurado,
        aseguradoNombreSelected: {
          value: action.payload.asegurado.nombre,
          valid: true,
          focus: false,
        },
        aseguradoApellidoSelected: {
          value: action.payload.asegurado.apellido,
          valid: true,
          focus: false,
        },
        aseguradoIdSelected: action.payload.asegurado.id,
        isLoadingAsegurado: false,
      };
    },
    [getAseguradoFailed]: (state, action) => {
      return {
        ...state,
        asegurado: null,
        aseguradoNombreSelected: {
          value: "",
          valid: false,
          focus: false,
        },
        aseguradoApellidoSelected: {
          value: "",
          valid: false,
          focus: false,
        },
        aseguradoIdSelected: null,
        isLoadingAsegurado: false,
      };
    },
    [postPolizaMultiramaInited]: (state, action) => {
      return {
        ...state,
        creatingPoliza: true,
        isLoading: true,
      };
    },
    [postPolizaMultiramaCompleted]: (state, action) => {
      return {
        ...state,
        polizaCreada: true,
        creatingPoliza: false,
        isLoading: false,
      };
    },
    [postPolizaMultiramaFailed]: (state, action) => {
      return {
        ...state,
        creatingPoliza: false,
        isLoading: false,
      };
    },
    [clearData]: (state, action) => {
      return {
        ...altaPolizaMultiramaReducerInitialState,
      };
    },
  },

  altaPolizaMultiramaReducerInitialState
);

export {
  altaPolizaMultiramaReducer,
  setNumeroPoliza,
  setRama,
  setCompania,
  setUsuario,
  setAseguradoNombre,
  setAseguradoApellido,
  setAseguradoNumeroDocumento,
  setDominio,
  setVigencia,
  setCobertura,
  setCantidadCuotas,
  setVigenciaDesde,
  setVigenciaHasta,
  setPremio,
  setSubmitting,
  setFormaPago,
  getRamas,
  getUsuarios,
  setPolizaFileName,
  setCuponeraFileName,
  setCedulaMercosurFileName,
  setTarjetaFileName,
  setIsProcessingPoliza,
  setIsProcessingCuponera,
  setIsProcessingCedulaMercosur,
  setIsProcessingTarjeta,
  postPolizaMultirama,
  clearData,
  clearUsuario,
  getAsegurado,
};
