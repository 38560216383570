import React from "react";
import { useSelector, useDispatch } from "react-redux";
import ItemDateCard from "~business/Cards/ItemDateCard";
import DetalleCard from "~business/Cards/DetalleCard";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {
  getAnulaciones,
  setFechaDesdeAnulaciones,
  setFechaHastaAnulaciones,
} from "./actions";

const useReduxSelector = () =>
  useSelector((state) => ({
    ...state.getAnulacionesReducer,
  }));

const AnulacionesCard = () => {
  const dispatch = useDispatch();
  const {
    fechaDesdeAnulacionesSelected,
    fechaHastaAnulacionesSelected,
    buttonEnabled,
    buttonText,
  } = useReduxSelector();

  const handleChangeFechaDesdeAnulaciones = (fechaDesdeAnulaciones) => {
    dispatch(setFechaDesdeAnulaciones(fechaDesdeAnulaciones));
  };
  const handleChangeFechaHastaAnulaciones = (fechaHastaAnulaciones) => {
    dispatch(setFechaHastaAnulaciones(fechaHastaAnulaciones));
  };
  const handleGetAnulaciones = () => {
    let data = {
      fechaDesde: fechaDesdeAnulacionesSelected.value,
      fechaHasta: fechaHastaAnulacionesSelected.value,
    };
    dispatch(getAnulaciones(data));
  };

  return (
    <>
      <DetalleCard title={"Anulaciones"}>
        <ItemDateCard
          label="Desde"
          value={fechaDesdeAnulacionesSelected.value}
          onChange={handleChangeFechaDesdeAnulaciones}
        />
        <ItemDateCard
          label="Hasta"
          value={fechaHastaAnulacionesSelected.value}
          onChange={handleChangeFechaHastaAnulaciones}
        />
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm="auto">
              <Button
                variant="contained"
                color="primary"
                onClick={handleGetAnulaciones}
                disabled={!buttonEnabled}
              >
                {buttonText}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DetalleCard>
    </>
  );
};

export default AnulacionesCard;
