import { queueMessage } from "~shared/snackbarsProvider/actions";
import { createAction, handleActions } from "redux-actions";

const getEmisionesInited = createAction(
  "GET_REPORTE_EMISIONES_INITED",
  (data) => ({ data })
);
const getEmisionesCompleted = createAction("GET_REPORTE_EMISIONES_COMPLETED");
const getEmisionesFailed = createAction(
  "GET_REPORTE_EMISIONES_FAILED",
  (error) => ({ error })
);

const getEmisionesDeComisionesInited = createAction(
  "GET_REPORTE_EMISIONES_DE_COMISIONES_INITED",
  (data) => ({ data })
);
const getEmisionesDeComisionesCompleted = createAction("GET_REPORTE_EMISIONES_DE_COMISIONES_COMPLETED");
const getEmisionesDeComisionesFailed = createAction(
  "GET_REPORTE_EMISIONES_DE_COMISIONES_FAILED",
  (error) => ({ error })
);

const setFechaDesde = createAction("SET_FECHA_DESDE", (fechaDesdeSelected) => ({
  fechaDesdeSelected,
}));
const setFechaHasta = createAction("SET_FECHA_HASTA", (fechaHastaSelected) => ({
  fechaHastaSelected,
}));

let date = new Date();
let firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
let lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
const getEmisionesInitialState = {
  fechaDesdeSelected: { value: firstDayOfMonth, valid: true, focus: false },
  fechaHastaSelected: { value: lastDayOfMonth, valid: false, focus: false },
  buttonEnabled: true,
  buttonText: "Descargar",
  buttonComisionesText: "Descargar (Comisiones)",
};

const getEmisionesReducer = handleActions(
  {
    [getEmisionesInited]: (state, action) => {
      return {
        ...state,
        buttonEnabled: false,
        buttonText: "Descargando...",
      };
    },
    [getEmisionesCompleted]: (state, action) => {
      return {
        ...state,
        buttonEnabled: true,
        buttonText: "Descargar",
      };
    },
    [getEmisionesFailed]: (state, action) => {
      return {
        ...state,
        errorMessage: action.payload.error.responseStatus.message,
        buttonEnabled: true,
        buttonText: "Descargar",
      };
    },
    [getEmisionesDeComisionesInited]: (state, action) => {
      return {
        ...state,
        buttonEnabled: false,
        buttonComisionesText: "Descargando...",
      };
    },
    [getEmisionesDeComisionesCompleted]: (state, action) => {
      return {
        ...state,
        buttonEnabled: true,
        buttonComisionesText: "Descargar (Comisiones)",
      };
    },
    [getEmisionesDeComisionesFailed]: (state, action) => {
      return {
        ...state,
        errorMessage: action.payload.error.responseStatus.message,
        buttonEnabled: true,
        buttonComisionesText: "Descargar (Comisiones)",
      };
    },
    [setFechaDesde]: (state, action) => {
      return {
        ...state,
        fechaDesdeSelected: action.payload.fechaDesdeSelected,
      };
    },
    [setFechaHasta]: (state, action) => {
      return {
        ...state,
        fechaHastaSelected: action.payload.fechaHastaSelected,
      };
    },
  },
  getEmisionesInitialState
);

const getEmisiones = (data) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getEmisionesInited(data));
      let response = await services.api.reportes().getEmisiones(data);

      // create a fake anchor and click it programatically to
      // force the file to download
      var xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
          var downloadUrl = URL.createObjectURL(xhttp.response);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = downloadUrl;
          a.download = "";
          a.click();
        }
      };

      xhttp.open("GET", response.url, true);
      xhttp.responseType = "blob";
      xhttp.send();

      dispatch(getEmisionesCompleted());
    } catch (error) {
      console.error(error);
      dispatch(getEmisionesFailed(error));
      dispatch(
        queueMessage(
          "Ocurrió un error al descargar el reporte. Intente nuevamente."
        )
      );
    }
  };
};

const getEmisionesDeComisiones = (data) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(getEmisionesDeComisionesInited(data));
      let response = await services.api.reportes().getEmisionesDeComisiones(data);

      // create a fake anchor and click it programatically to
      // force the file to download
      var xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
          var downloadUrl = URL.createObjectURL(xhttp.response);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = downloadUrl;
          a.download = "";
          a.click();
        }
      };

      xhttp.open("GET", response.url, true);
      xhttp.responseType = "blob";
      xhttp.send();

      dispatch(getEmisionesDeComisionesCompleted());
    } catch (error) {
      console.error(error);
      dispatch(getEmisionesDeComisionesFailed(error));
      dispatch(
        queueMessage(
          "Ocurrió un error al descargar el reporte. Intente nuevamente."
        )
      );
    }
  };
};

export { getEmisiones, getEmisionesDeComisiones, getEmisionesReducer, setFechaDesde, setFechaHasta };
